<template>
  <div class="container-fluid my-5">
    <div class="card card-default">
      <!-- /.card-header -->
      <div class="card-header">
        <h3 class="card-title " v-if="edit">Pet Edit</h3>
        <h3 class="card-title " v-else>Add Pet</h3>
      </div>
      <!-- /.card-body -->
      <div class="card-body content">
        <div class="form-row">
          <div class="col-md-6 mb-2">
            <label class="font-weight-600"
              >Name <span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.name }"
              v-model="name"
              placeholder="Name"
            />
            <span class="text-danger" v-if="errors.name">{{
              this.errors.name[0]
            }}</span>
          </div>

          <div class="col-md-6 mb-2">
            <label class="font-weight-600"
              >Pet<span class="text-danger">*</span></label
            >
            <select
              name=""
              class="form-control noBorder"
              id=""
              v-model="selected_species"
            >
              <option value="">--Select your pet--</option>
              <option :value="species" v-for="species in species">{{
                species.pet_name
              }}</option>
            </select>
            <span class="text-danger" v-if="errors.species">{{
              this.errors.species[0]
            }}</span>
          </div>
          <div class="col-md-6 mb-2">
            <label class="font-weight-600"
              >Breed<span class="text-danger">*</span></label
            >
            <select
              name=""
              class="form-control noBorder"
              id=""
              v-model="selected_breed"
            >
              <option value="">--Select Breed--</option>
              <option :value="breed" v-for="breed in breeds">{{
                breed.breed_name
              }}</option>
            </select>
            <span class="text-danger" v-if="errors.breed">{{
              this.errors.breed[0]
            }}</span>
          </div>
          <div class="col-md-6 mb-2">
            <label class="font-weight-600"
              >AgeRange<span class="text-danger">*</span></label
            >
            <select
              name=""
              class="form-control noBorder"
              id=""
              v-model="selected_age"
            >
              <option value="">How old is this pet?</option>
              <option :value="age" v-for="age in ageranges">{{
                age.age_label
              }}</option>
            </select>
            <span class="text-danger" v-if="errors.age">{{
              this.errors.age[0]
            }}</span>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <b-form-group class="font-weight-600">
                <label
                  class="font-weight-600 d-flex justify-content-center pb-2"
                  >Gender</label
                >
                <div class="row">
                  <div class="col-md-6">
                    <b-form-radio v-model="gender" name="male" value="Male"
                      >Male</b-form-radio
                    >
                  </div>
                  <div class="col-md-6">
                    <b-form-radio v-model="gender" name="female" value="Female"
                      >Female</b-form-radio
                    >
                  </div>
                  <span class="text-danger" v-if="errors.gender">{{
                    this.errors.gender[0]
                  }}</span>
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-row">
              <div class="img-thumbnail mb-5">
                <center>
                  <img :src="preview_image" class="preview-img1" />
                </center>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-row">
              <div class="col-md-12 mb-2">
                <label class="font-weight-600">Pet</label>
                <input
                  type="file"
                  v-on:change="onImageChange"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-footer -->
      <div class="card-footer">
        <div class="col-md-12 center py-3">
          <center>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm col-sm-1  mb-2 mr-3"
              @click="savePet()"
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm col-sm-1  mb-2 mr-1"
              @click="cancelForm()"
            >
              Cancel
            </button>
          </center>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSelectImage from "vue-select-image";

export default {
  name: "CreateEdit",
  props: ["petData", "edit"],
  components: {
    VueSelectImage,
  },
  data() {
    return {
      errors: [],
      name: this.petData.petwizardDetails.name,
      gender: this.petData.petwizardDetails.gender,
      species: null,
      image: null,
      preview_image: " ",
      breeds: null,
      selected_age: null,
      ageranges: null,
      selected_species: this.petData.petwizardDetails.type,
      selected_breed: this.petData.petwizardDetails.breed,
      selected_age: this.petData.petwizardDetails.ageRange,
      weight: this.petData.petwizardDetails.weight,
    };
  },
  created() {
    this.getAllSpecies();
    this.getAllBreeds();
    this.getAllrange();
    this.showPetImage();
  },
  methods: {
    getAllrange() {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var fetch_url = process.env.VUE_APP_URL + "customer/pet/getAllAgeranges";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.ageranges = data.allAgeranges;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getAllBreeds() {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var fetch_url = process.env.VUE_APP_URL + "customer/pet/getBreeds";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          id: this.selected_species.id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.breeds = data.breeds;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getAllSpecies() {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var fetch_url = process.env.VUE_APP_URL + "customer/pet/getSpecies";
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.species = data.species;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    savePet() {
      this.errors = [];
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.token;
      var user_id = userdata_array.user.id;
      let method_type = "";
      let fetch_url = "";
      if (this.edit === false) {
        method_type = "post";
        fetch_url = process.env.VUE_APP_URL + "customer/pet/addPet";
      } else {
        method_type = "post";
        fetch_url = process.env.VUE_APP_URL + "customer/pet/updatePet";
      }
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          name: this.name,
          gender: this.gender,
          species: this.selected_species.id,
          breed: this.selected_breed.id,
          age: this.selected_age.id,
          weight: this.weight,
          pet_id: this.petData.id,
          user_id: user_id,
          image: this.image,
          img_type: "web",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          if (data.status === true) {
            this.$swal.fire("Good to Go", data.message, "success");
            this.$emit("closePetEdit", true);
          } else if (data.status === false) {
            this.$swal.fire("Error", "Pet Data Not Saved !", "error");
          } else {
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          this.$swal("Pet Data", "Error : " + err.message, "error");
        });
    },
    cancelForm() {
      this.$emit("closePetEdit", true);
    },
    getWizardDetails() {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var fetch_url =
        process.env.VUE_APP_URL + "customer/wizard/getWizardDetails";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          user_id: userdata_array.user.id,
          contact_id: this.contactData.id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.wizard_Details = data.wizardDetails;
          this.personas = data.wizardDetails.persona;
          this.activelifestyles = data.wizardDetails.activeLifeStyle;
          this.charactertrait = data.wizardDetails.characterTrait;
        });
    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
        vm.preview_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    showPetImage() {
      if (this.petData.image === null) {
        this.preview_image = this.petData.petwizardDetails.type.pet_icon;
      } else {
        this.preview_image = this.petData.pet_image;
      }
    },
  },
};
</script>
