<template>
  <div class="container-fluid">
    <div class="card card-default mt-5">
      <!-- /.card-header -->
      <div class="card-header">
        <h3
          class="card-title text-center mb-5"
          style="bottom: -15px !important; position: relative;"
        >
          Pets
        </h3>
      </div>
      <div class="card-body content ml-4">
        <b-overlay :show="loader" rounded="sm">
          <div class="ml-5">
            <table id="pet-btable" class="table table-border hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Pet</th>
                  <th>Breed</th>
                  <th>Age Range</th>
                  <th>Gender</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="pet in pets">
                  <td class="break-word">{{ pet.petwizardDetails.name }}</td>
                  <td>
                    {{
                      pet.petwizardDetails.type
                        ? pet.petwizardDetails.type.pet_name
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      pet.petwizardDetails.breed
                        ? pet.petwizardDetails.breed.breed_name
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      pet.petwizardDetails.ageRange
                        ? pet.petwizardDetails.ageRange.age_label
                        : ""
                    }}
                  </td>
                  <td>{{ pet.petwizardDetails.gender }}</td>
                  <td>
                    <b-button
                      size="sm"
                      variant="outline-primary mx-1"
                      class="fa fa-edit"
                      @click="editPet(pet)"
                      v-b-popover.hover.top="'Edit'"
                    ></b-button>
                    <b-button
                      size="sm"
                      variant="outline-danger mx-1"
                      @click="deletePet(pet)"
                      class="fa fa-trash"
                      v-b-popover.hover.top="'Delete'"
                    ></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-overlay>
      </div>
      <!-- /.card-footer -->
      <div class="card-footer">
        <div class="col-xs-12"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PetsTable",
  props: ["showPetTable", "petData"],
  data() {
    return {
      selectMode: "single",
      pets: [],
      petFields: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      filter: null,
      currentRows: 0,
      filterOn: [],
      loader: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/pet/getPetwizardDetails";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          user_id: userdata_array.user.id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          if (data.petwizardDetails.length == 0) {
            this.showSpecies = true;
          } else {
            this.pets = data.petwizardDetails;
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    addContact() {
      this.$emit("addContact", true);
    },
    editPet(pet) {
      this.$emit("petEdit", pet);
    },
    deletePet(pet) {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Do you want to remove  " + pet.petwizardDetails.name + "!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            fetch(process.env.VUE_APP_URL + "customer/pet/deletePet", {
              method: "post",
              headers: {
                "content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: "Bearer " + api_token,
              },
              body: JSON.stringify({
                id: pet.id,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.status === true) {
                  this.loader = false;

                  this.pets = this.pets.filter(
                    (petData) => petData.id !== pet.id
                  );

                  this.$swal.fire("Deleted", data.message, "success");
                } else {
                  this.loader = false;
                  this.$swal.fire("Error!", data.message, "error");
                }
              })
              .catch(function(err) {
                console.log("Fetch Error :-S", err);
              });
          }
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.break-word {
  word-break: break-word;
}
</style>
